<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isExternal?: boolean
    url: string
    className?: string
    theme?: string
    bold?: boolean
  }>(),
  { isExternal: false, bold: false }
)

let colorClass = `${props.className} border-white text-white text-white hover:text-primary after:content-[''] after:rounded-3xl after:-top-[1px] after:left-0 after:absolute after:w-[100%] after:h-[105%] after:bg-white after:z-10 after:-translate-y-full after:transition-all after:duration-500 hover:after:translate-y-0`
if (props.theme === 'primary') {
  colorClass = `${props.className} border-white bg-white text-primary hover:border-primary hover:text-white hover:bg-primary`
} else if (props.theme === 'secondary') {
  colorClass = `${props.className} border-white bg-white text-secondary-darkest hover:border-secondary-darkest hover:text-white hover:bg-secondary-darkest`
} else if (props.theme === 'ghost') {
  colorClass = `${props.className} border-white bg-transparent text-white hover:border-secondary-darkest hover:text-white hover:bg-secondary-darkest`
}
if (props.bold) {
  colorClass += ' font-semibold'
} else {
  colorClass += ' font-normal'
}
</script>

<template>
  <a
    :href="url"
    :class="colorClass"
    :target="isExternal ? '_black' : '_self'"
    class="px-6 py-2 z-20 transition-all duration-500 overflow-hidden relative border rounded-3xl justify-center uppercase"
  >
    <div class="z-20 flex flex-row gap-2 items-center relative">
      <slot></slot>
      <div v-if="isExternal" class="overflow-hidden text-lg md:text-md">
        <font-awesome-icon icon="fa-solid fa-arrow-up-right-from-square" />
      </div>
    </div>
  </a>
</template>
