<script setup lang="ts">
import emailjs from '@emailjs/browser'
import type { EmailJSResponseStatus } from '@emailjs/browser'
import Grid from '@components/Grid.vue'
import Container from '@components/Container.vue'
import Block from '@components/Block.vue'
import { ref } from 'vue'
import LinkButton from '@components/button/LinkButton.vue'

const email = ref<string>()
const name = ref<string>()
const message = ref<string>()
const responseMessage = ref<string>()
const form = ref<Element>()
const sending = ref<boolean>()

const sendEmail = () => {
  sending.value = true
  responseMessage.value = ''
  emailjs
    .sendForm(
      'service_58ukeig',
      'template_8t7bksc',
      form.value as HTMLFormElement,
      'nh1iLXdrwrQoO12Ir'
    )
    .then((response: EmailJSResponseStatus) => {
      sending.value = false
      responseMessage.value = 'Thank you for your message!'
    })
    .catch((error: any) => {
      sending.value = false
      responseMessage.value = 'Something went wrong'
    })
}
</script>
<template>
  <Container className="bg-gradient-purple w-full flex items-center justify-center">
    <Block className=" w-full">
      <Grid id="contact">
        <div class="col-span-full md:col-start-1 md:col-span-3 lg:col-span-5">
          <div class="flex-col flex gap-10">
            <div class="text-white text-body font-body">
              <h2 class="font-sectionheading text-sectionheading mb-4 md:mb-6 text-white">
                Contact
              </h2>
              <div class="flex flex-col gap-4">
                <p>
                  Feel free to reach out to me through this form for any inquiries, collaborations
                  or just tips for must watch movie|series. Don't be scared to reach out!
                </p>
                <p>
                  Alternatively, you can also connect with me on LinkedIn and send me a message
                  there.
                </p>
              </div>
            </div>
            <div class="flex">
              <LinkButton :is-external="true" url="https://linkedin.com/in/camisteine">
                LinkedIn</LinkButton
              >
            </div>
          </div>
        </div>
        <div
          class="col-span-full md:col-start-4 md:col-span-3 lg:col-start-7 lg:col-span-6 md:pl-12 xl:pr-16"
        >
          <form @submit.prevent="sendEmail" id="contactForm" ref="form">
            <div class="flex flex-col gap-6 py-8 md:py-0">
              <div class="rounded-lg bg-white py-3 relative overflow-hidden group">
                <input
                  required
                  type="text"
                  v-model="name"
                  name="name"
                  class="peer w-full outline-none px-2 placeholder-opacity-0"
                  placeholder="Name"
                />
                <label
                  class="absolute font-medium uppercase text-primary transition-all z-20 opacity-0 peer-placeholder-shown:opacity-65 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:opacity-0 peer-focus:-left-2"
                  >Name</label
                >
              </div>
              <div class="rounded-lg bg-white py-3 relative overflow-hidden">
                <input
                  required
                  type="text"
                  v-model="email"
                  name="email"
                  class="peer w-full outline-none px-2 placeholder-opacity-0"
                  placeholder="Email"
                />
                <label
                  class="absolute font-medium uppercase text-primary transition-all z-20 opacity-0 peer-placeholder-shown:opacity-65 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:opacity-0 peer-focus:-left-2"
                  >Email</label
                >
              </div>
              <div class="rounded-lg bg-white py-3 relative overflow-hidden">
                <ClientOnly>
                  <textarea
                    required
                    name="message"
                    v-model="message"
                    cols="30"
                    rows="5"
                    class="peer w-full outline-none px-2 placeholder-opacity-0"
                    placeholder="Message"
                  >
                  </textarea>
                </ClientOnly>
                <label
                  class="absolute text-primary font-medium uppercase transition-all z-20 opacity-0 peer-placeholder-shown:opacity-65 peer-placeholder-shown:top-3 peer-placeholder-shown:left-3 peer-focus:opacity-0 peer-focus:-left-2"
                >
                  Message
                </label>
              </div>

              <div class="flex flex-row justify-end">
                <div
                  :class="!sending ? 'animation-shake' : ''"
                  class="py-3 px-10 flex-row flex gap-4 duration-300 transition-all relative bg-secondary text-purple hover:text-white uppercase font-body rounded-3xl hover:bg-secondary-darker cursor-pointer"
                >
                  <input
                    type="submit"
                    :value="!sending ? 'Send' : 'Sending'"
                    class="bg-transparent cursor-pointer uppercase"
                  />
                  <div :class="!sending ? 'opacity-0 hidden' : 'opacity-100'">
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div>
                <p class="text-white text-body font-body">
                  {{ responseMessage }}
                </p>
              </div>
            </div>
          </form>
        </div>
      </Grid>
    </Block>
  </Container>
</template>

<style scoped>
::-webkit-input-placeholder {
  /* WebKit browsers */
  opacity: 0;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  opacity: 0;
  -webkit-opacity: 0;
  -khtml-opacity: 0;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  opacity: 0;
}

.animation-shake {
  transform-origin: center;
  animation-name: shakeMe;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0s;
}
@keyframes shakeMe {
  2%,
  18% {
    transform: rotate(0deg);
  }

  4%,
  16% {
    transform: rotate(6deg);
  }

  6%,
  10%,
  14% {
    transform: rotate(0deg);
  }

  8%,
  12% {
    transform: rotate(-6deg);
  }

  18.1% {
    transform: rotate(0);
  }
}
</style>
